html {
  font-family: "Poppins", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 -10px #2e8bc0;
  }

  40% {
    box-shadow: 0 0 20px #2e8bc0;
  }

  60% {
    box-shadow: 0 0 20px #2e8bc0;
  }

  100% {
    box-shadow: 0 0 -10px #2e8bc0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spacer {
  padding-top: 10px;
}

.page-wrapper {
  height: 100%;
}

.page-wrapper > .makeStyles-root-1,
.page-wrapper > .makeStyles-root-1 > .makeStyles-content-2 {
  height: 100%;
  background-color: white !important;
}

/* table css */

/* @import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";

.ag-header-row.ag-header-row-column {
  color: white !important;
}

.ag-header-cell,
.ag-cell,
.ag-header-cell .ag-header-cell-sortable,
.ag-header-cell .ag-floating-filter,
.ag-header-group-cell,
.ag-header-group-cell-with-group {
  text-align: left;
  display: block;
  height: 60px;
}

.ag-theme-alpine {
  color: #badbfe;
}

.ag-theme-alpine .ag-row {
  font-size: 12px !important;
}

.ag-theme-alpine .ag-header {
  background-color: #e7edf3 !important;
} */

/* .ag-theme-alpine .ag-row-odd {
  background-color: #ffffff;
} */

/* .ag-header-row,
.ag-row,
.ag-header-row-column-filter {
} */

/* .ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
  text-align: left;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  content: " \2714";
}

.ag-cell-value {
  text-overflow: "ellipsis" !important;
  white-space: "nowrap" !important;
  display: block;
} 

.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.actions-button-cell.ag-cell-focus.ag-cell-value {
  overflow: visible !important;
}

.ag-row {
  z-index:0;
}
.ag-row-focus {
  z-index:1;
}


*/

/* .ag-header{
  height: 30px !important;
  min-height: 30px !important;
} */

/* .ag-header-cell.ag-header-cell-sortable.ag-focus-managed {
  top:8px;
} */

/* .ag-header-cell.ag-header-cell-sortable.ag-focus-managed {
  height:30px;
}

.ag-header-group-cell-label {
  height:30px;
} */

.content-spacer {
  padding-top: 10px !important;
}

.map-container {
  height: 100vh;
  width: 100%;
}

.clicked-coord-label {
  position: absolute;
  right: 0;
  bottom: 0;
  background: white;
  border-radius: 5px;
}

.clicked-coord-label p {
  margin: 10px;
}

.ia-forecast {
  background-color: #cae9c8 !important;
}

.LY {
  background-color: #bcdeff !important;
}

#roottest {
  padding: 10px;
}

.app-route {
  height: 100vh;
}

#sub-content > .page-content.monday-smart-v3 {
  height: 100%;
}

.monday-smart-v3 #roottest {
  background-color: #f5f6fa;
  overflow-x: hidden;
}

.monday-smart-v3 .app-route {
  height: 100%;
}

.monday-smart-v3 .ia-styles.ia-btn.ia-btn-outlined svg path {
  fill: rgba(95, 99, 104, 1) !important;
}

/* .impact-table-popover > table,
tr,
td {
  border: 1px solid lightgray;
  border-collapse: collapse;
} */

.ia-assort-carryover {
  background-color: #f7eae9 !important;
}

.impact-customized-card {
  display: flex;
}

.progress {
  display: flex;
  justify-content: left;
  align-items: center;
  width: max-content;
  gap: 20px;
  padding: 5px;
}

.progress--text {
  text-align: left;
  max-width: 200px;
}

.secondary-bar {
  position: absolute;
  left: 0;
  z-index: 0;
}

.auth-error {
  font-size: 2.5em;
  position: fixed;
  top: 25%;
  left: 30%;
  display: flex;
  flex-direction: column;
  aspect-ratio: 1/1;
  width: max-content;
}

.tech-error {
  height: 100%;
  display: flex;
  justify-content: center;
  /* padding: 100px; */
}

.impact-filter-container {
  padding-bottom: 10px;
}

.ia-assort-line-review {
  background-color: lightgray !important;
}

/* horizontally scrollable kpi container */
.kpi-scroll--container {
  display: inline-flex;
  gap: 20px;
  max-width: 100%;
  overflow-x: scroll;
  padding: 20px;
}

.kpi-scroll--card {
  border: 1px solid lightgrey;
  border-radius: 5px;
  min-width: 200px;
  flex-shrink: 0;
}

/**/

/*cell tooltip*/
.custom-tooltip {
  background-color: grey;
  border: none;
  border-radius: 5px;
  max-width: 300px;
  padding: 2px;
}

.custom-tooltip p {
  color: white;
  margin: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/**/

/* loading screen */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

/* fixing border issue in button group */

.button-group-impact {
  box-sizing: content-box;
}

.button-group-impact button {
  height: inherit !important;
}

/* Grocery KPI Cards */

.grocery-kpi-card-item.card {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 86px;
}

.grocery-icon-conatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  background: #0055af10 0% 0% no-repeat padding-box;
  border-radius: 28px;
}

.grocery-text-container {
  display: flex;
  flex-direction: column;
}

.grocery-text-heading {
  text-align: left;
  font: normal normal normal 12px Poppins;
  letter-spacing: 0px;
  color: #758498;
  opacity: 1;
}

.grocery-text-value {
  text-align: left;
  font: normal normal 600 22px Poppins;
  letter-spacing: 0px;
  color: #1d1d1d;
  opacity: 1;
}

.label-component {
  display: flex;
  align-items: center;
}

.ag-header-group-cell-label {
  display: flex;
  justify-content: center;
}

.ag-header-cell-label {
  display: flex;
  justify-content: center;
}

.glowing-button {
  animation: glowing 3000ms infinite;
}

/* swipe-able drawer */

.swipe-drawer {
  height: 100%;
}
.drawer-header {
  display: flex;
  justify-content: space-between;
  padding: 12px 32px;
  /* margin-bottom: 10px; */
  border-bottom: 1px solid #d4d4d4;
  height: 50px;
}

.drawer-body {
  position: relative;
  overflow-y: auto;
  height: calc(100% - 50px);
  padding: 16px 32px;
}

.group-filter-section {
  border-style: ridge;
  border-width: 1px;
  border-radius: 5px;
  padding: 15px;
  border-color: #057dcd;
}

.custom-aggregate-textbox {
  border-radius: 4px;
  border: 1px solid #babfe7;
  height: 1.75rem;
  max-width: 100%;
  padding-left: 5px;
}
.custom-aggregate-textbox:focus {
  outline: none;
  box-shadow: 0 0 5px 0 #3498db;
}

.tab-container {
  white-space: nowrap;
  overflow: auto;
}

.hide-fileselector-buttons .fileSelector .fileSelector-button-container {
  display: none;
}

.impact-action-components-container .select-container > .select-button {
  min-width: 220px;
}

.custom-badge-component {
  cursor: pointer;
}

.select-dropdown-container:has(.IA-filter-dropdown) {
  z-index: 2000;
}

.hide-year-radio li:nth-child(6) .radio-container {
  display: none;
}
.html-content ul {
  padding-inline-start: 24px;
  line-height: 18px;
  font-size: 13px;
  margin-block-start: 1em;
  margin-block-end: 1em;
}
.html-content ul li {
  list-style: disc !important;
}
.img-with-tick {
  position: relative;
  display: flex;
  align-items: center;
}
.img-with-tick::after {
  content: "\2714";
  position: absolute;
  top: -5px;
  right: 0px;
  color: rgb(34, 195, 34);
}
.custom-tippy {
  background-color: #f0f0f0;
  color: #333;
  padding: 5px 10px;
  font-size: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.html-decoder-container strong {
  font-weight: bold;
}

.html-decoder-container ul li {
  list-style-type: disc;
}

.html-decoder-container h1 {
  font-size: 2em;
  font-weight: bold;
  margin: 0.67em 0;
  padding: 0;
  line-height: 1.2;
  text-decoration: none;
}

.html-decoder-container h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0.83em 0;
  padding: 0;
  line-height: 1.2;
  text-decoration: none;
}

.html-decoder-container p {
  font-size: 1em;
  font-weight: normal;
  line-height: 1.5;
  text-decoration: none;
}

.html-decoder-container a {
  color: blue;
  text-decoration: underline;
}
.html-decoder-container a:hover {
  color: purple;
}

.html-decoder-container span {
  display: inline;
  margin: 0;
  padding: 0;
}
