.ag-theme-balham .ag-header-group-cell-label {
	justify-content: center;
	align-items: center;
	font-weight: "bold";
}
.bold-header {
	font-weight: 800;
}

.lighter-font-row {
	font-weight: 300;
}
.ag-right-aligned-header {
	font-weight: 800;
}

.rag-red {
	background-color: #cc222244;
}
.rag-green {
	background-color: #33cc3344;
}

// .ag-theme-alpine .ag-header-row {
// 	height: 40px !important;
// 	/* min-height: 40px !important; */
// }

// .ag-header-group-cell {
//   background: #efefef !important;
// }

// .ag-cell .input-container {
// 	height: 32px;
// }

/* for table */
// .ag-header-cell {
// 	background-color: #dbe7fb !important;
// }

// .ag-header-viewport {
// 	background-color: #dbe7fb !important;
// }

// .ag-header-group-cell {
// 	background-color: #dbe7fb !important;
// }
