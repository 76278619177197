body {
  margin: 0;
  font-family: Verdana;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Verdana;
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid gray;
}

.filter-container {
  padding: 15px;
  border: 1px solid #c8ced7;
  border-radius: 3px;
  position: relative;
  padding-left: 30px;
}

.filter-container h2 {
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #1d1d1d;
}

.filter-container::after {
  content: " ";
  position: absolute;
  width: 2px;
  background: #0055af;
  top: 10px;
  bottom: 10px;
  left: 15px;
}

#rdts2_trigger {
  width: 100%;
  height: 35px;
  max-height: 35px;
  border-radius: 3px;
  border: 0.5px solid #96a8b9;
}

#rdts2 .dropdown {
  width: 307px;
}

.react-daterange-picker__wrapper {
  height: 35px !important;
}

.filterGroup label {
  font-size: 14px !important;
  color: #758490 !important;
}

hr {
  margin: 1% !important;
  border-top: 1px solid #d4d4d4 !important;
}

.cluster-container {
  padding: 15px;
  border: 1px solid #c8ced7;
  border-radius: 3px;
  position: relative;
  padding-left: 30px;
}

.cluster-container::after {
  content: " ";
  position: absolute;
  width: 2px;
  background: #0055af;
  top: 10px;
  bottom: 10px;
  left: 15px;
}

.card-checkbox-container {
  border: 1px solid #d4d4d4;
  padding: 10px;
  margin-right: 10px;
  min-height: 300px;
  border-radius: 3px;
  background: rgba(239, 239, 239, 0.4);
}

.form-main-container {
  padding: 15px;
  border: 1px solid #c8ced7;
  border-radius: 3px;
  position: relative;
  padding-left: 30px;
}

.form-main-container::after {
  content: " ";
  position: absolute;
  width: 2px;
  background: #0055af;
  top: 10px;
  bottom: 10px;
  left: 15px;
}

.ag-header-cell-label {
  color: #1d1d1d;
}

.card-parent-container {
  padding: 15px;
  border: 1px solid #c8ced7;
  border-radius: 3px;
  position: relative;
  padding-left: 30px;
  margin: 15px;
}

.card-parent-container::after {
  content: " ";
  position: absolute;
  width: 2px;
  background: #0055af;
  top: 10px;
  bottom: 10px;
  left: 15px;
}

/* Impact Ui Fixes */

.checkbox-container {
  box-sizing: content-box !important;
}

.grocery-price-smart-rules-actionkpi-cards {
  max-height: 160px;
}

.card-top {
  margin-bottom: 10px;
  max-height: 100px;
}

.tag-container {
  padding: 0px 5px;
}

.datePicker-date-input {
  width: 100% !important;
}

.datePicker-input-container > div {
  width: 100% !important;
}

.dateRangePicker-label,
.datePicker-label {
  margin-bottom: 4px;
}

/* .dateRangePicker-input-container{
  height:37px !important;
  width: 100% !important;
}

.dateRangePicker-input-start-date {
  width:50% !important;
}
.dateRangePicker-input-end-date {
   width:50% !important;
} */

@media screen and (max-width: 1399px) and (min-width: 1200px) {
  .dateRangePicker-input-container {
    height: 28px !important;
  }
  .dateRangePicker-input-end-date {
    padding-left: 8px;
    background-position: center right 6px;
    background-size: 12px;
  }
}

@media screen and (min-width: 1400px) {
  .dateRangePicker-input-end-date {
    padding-left: 8px !important;
    background-position: center right 10px !important;
    background-size: 15px !important;
  }
}

/* .label-component{
  font-weight: 500 !important;
} */

.select-label p {
  margin-bottom: 4px;
}

.stepper {
  justify-content: center;
}

@media screen and (max-width: 1399px) and (min-width: 1200px) {
  .card {
    min-width: 100% !important;
  }
}

/* tab another components */
.tabs-other-component {
  position: absolute;
  top: 15px;
  right: 0;
}

label#demo-row-radio-buttons-group-label {
  font-size: 12px;
  color: rgb(117, 132, 144);
  margin-bottom: 4px;
  text-align: left;
}

.input-container input {
  width: 100% !important;
}

/* .select-button {
  min-width:100% !important;
} */

/* @media screen and (max-height: 600px) {
  .modal-body-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    bottom: 10px;
    max-height: none !important;
    overflow: none !important;
  }

  .modal-content-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    bottom: 45px;
    max-height: none !important;
  }
  .modal-buttons-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    padding-top: 10px;
}
} */

.fileSelector-dropzone {
  width: 100% !important;
}

.fileSelector {
  width: 100% !important;
}

.textarea-container .textarea-label {
  font-size: 12px;
  color: #758490;
  margin-bottom: 1px;
  text-align: left;
}

.textarea-container textarea {
  margin-top: 0 !important;
}

/* fixed tab label spacing */
.MuiTabs-flexContainer.MuiTabs-flexContainerVertical.css-lfwcke-MuiTabs-flexContainer
  button {
  padding: 0 !important;
}

/* fixed select width inside table cell */
/* .cell-table-select .select-button {
	min-width: 100% !important;
} */

/* fixed snackbar appearing very bottom of screen */
.MuiSnackbar-anchorOriginBottomLeft {
  bottom: 75px !important;
}

/* fixed panel alignment */
.panel-container {
  top: 0 !important;
  z-index: 1;
}

/* fixed extra hidden spacing from datepicker component */
.dateRangePicker-input-main-container {
  width: 237px !important;
}

/* fixed font size issue in drop message area in ag grid */
.ag-theme-alpine .ag-column-drop-empty-message {
  font-size: 12px !important;
}

.homedepot-tooptip {
  background: #444d54 !important;
  min-width: 30rem;
}
.homedepot-tooptip .MuiTooltip-arrow {
  color: #444d54;
}
.hide-true
  > .MuiAccordionSummary-root
  > .MuiAccordionSummary-expandIconWrapper {
  display: none !important;
}

.icon-dropdown .MuiBox-root {
  display: flex !important;
  align-items: center !important;
  padding-left: 8px !important;
  padding-right: 4px !important;
}
.icon-dropdown .MuiBox-root .MuiSvgIcon-root path {
  color: #394960 !important;
  fill: #394960 !important;
}
.icon-dropdown .MuiBox-root svg {
  width: 16px !important;
  height: 16px !important;
}
.left-align-text {
  justify-content: start !important;
}
.custom-tool-panel .ag-root-wrapper,
.custom-tool-panel .ag-header,
.custom-tool-panel .ag-header-cell,
.custom-tool-panel .ag-cell,
.custom-tool-panel .ag-pinned-left-header,
.custom-tool-panel .ag-pinned-left-cols-container,
.custom-tool-panel .ag-header-cell-label,
.custom-tool-panel .ag-header-viewport {
  border: none !important;
  background-color: white !important;
}

.hide-table-header .ag-header {
  display: none !important;
}

.loading-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
}

.impact_drawer_container.impact_drawer_container_large.perf-causal-panel-ms-v3,
.impact_drawer_container.impact_drawer_container_large.kpi-panel-ms-v3 {
  width: 700px;
}

.perf-causal-panel-ms-v3 .impact_drawer_header,
.kpi-panel-ms-v3 .impact_drawer_header {
  display: none !important;
}

.perf-causal-panel-ms-v3 .impact_drawer_body,
.kpi-panel-ms-v3 .impact_drawer_body {
  height: 100% !important;
}

.monday-smart-v3 {
  .container {
    box-shadow: none !important;
  }
  .impact-info-panel-container {
    margin: 0 -35px;
    width: auto;
    padding: 8px 34px;
  }
  .ia-styles.ia-btn.ia-btn-outlined {
    border-color: rgba(195, 200, 212, 1);
    background-color: white;
    color: #1f2b4d;
  }
  .ia-styles.ia-btn.ia-btn-outlined svg {
    color: rgba(95, 99, 104, 1) !important;
  }
  .ia-styles.ia-btn.ia-btn-outlined svg path {
    fill: rgba(95, 99, 104, 1) !important;
  }
  .highcharts-container {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .impact-table-main-header [data-icon="row-width"],
  .impact-table-main-header [data-icon="table-settings"],
  .table-header-hidden .impact-table-main-header {
    display: none !important;
  }

  .custom-ag-menu-icon-button {
    display: none !important;
  }
}
/* quick-css-fix-jaymin-karthik -10 jan 2025 */
.selectlist-item-container:last-child {
  border-bottom: none;
}

#roottest:has(.impact-home-container-V3) {
  background-color: #eff2fe;
  overflow: scroll
}

.impact-home-container-V3 {
  height: 100%;
}