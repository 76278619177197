.selectlist-container {
  overflow: auto;
}
.selectlist-container::-webkit-scrollbar {
  display: none;
}
.content-container {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #909090;
  font-size: 12px;
}
.selectlist-item-container {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 25px;
  align-items: center;
  border-bottom: 1px solid #cccccc;
}
.selectlist-item-container:has(+ .selectlist-item-container-active) {
  border-bottom: none;
}
.selectlist-item-container-active {
  border: 1px solid #cccccc;
  border-right: none;
  background-color: #f2f7ff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  z-index: 9999;
}

.selectlist-item-container-tag {
  display: flex;
  border: 1px solid black;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  height: 50px;
  width: 50px;
  font-weight: 500;
  border-color: rgb(169, 169, 170);
}
.selectlist-tag-background {
  position: absolute;
  left: 0;
  height: 100%;
  width: 70px;
  background-color: rgba(100, 100, 100, 0.05);
}
