.monday-kpi-card{
    background-color: rgb(255,255,255);
    max-height: 235px;
    width: 225px;
    border-radius: 10px;
    padding: 20px;
    flex-shrink: 0;
    box-shadow: rgba(0,0,0,.12) 0px 0px 8px;
    transition: all .5s ease;
}
.monday-kpi-card:hover{
    border: 1px solid #4993dd;
    background-color: #f3f9ff;
    box-shadow: rgba(0,0,0,.12) 0px 0px 8px 5px;
    cursor: pointer;
    transition: all .5s ease;
}
.monday-kpi-card-header{
    font-size: 14px;    
    line-height: 21px;
    letter-spacing: 1%;
    font-weight: 400;
    color: #394960;
    margin-top: -5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.monday-kpi-card-header-value{
    font-size: 24px;    
    line-height: 36px;
    letter-spacing: 1%;
    font-weight: 500;
    color: #1d1d1d;
    margin-bottom: 8px;
}
