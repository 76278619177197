.variant-a-chip {
  display: flex;
  align-items: center;
  background-color: #eff0f8;
  border-radius: 10px;
  padding: 4px 10px;
  width: max-content;
  gap:10px;
  font-size: 15px;
}
.variant-a-chip.selected {
  border: 1px solid #afafaf;
}
.variant-a-container {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  cursor: pointer;
}
