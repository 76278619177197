.Tree {
	text-align: center;
	margin: 5px;
	width: 100%;
	overflow-x: scroll;
	padding-bottom: 20px;
}

.CircularProgressbar .CircularProgressbar-text {
	fill: #3e98c7;
	font-size: 20px;
	dominant-baseline: middle;
	text-anchor: middle;
	font-weight: bold;
}

.tree-node-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
}

.node-container {
	display: flex;
	justify-content: row;
	align-items: center;
	border: 1px solid silver;
	border-radius: 30px;
	padding: 10px;
	font-size: 12px;
	width: 285px;
	height: 55px;
	background-color: white;
	position: relative;
}

.circular-progress-container {
	width: 45px;
	height: 45px;
	margin-left: -7px;
}

.node-inner-left-container {
	position: absolute;
	padding-left: 40px;
	text-align: left;
	color: gray;
	height: 50px;
	line-height: 20px;
}

.node-inner-right-container {
	padding-left: 150px;
	padding-top: 10px;
	text-align: left;
	color: gray;
	font-size: 11px;
}

.right-icon-container {
	position: absolute;
	padding-left: 260px;
}

.expand-node-icon {
	cursor: pointer;
	font-size: 16px;
	color: #0055af;
	height: 18px;
	width: 24px;
	background-color: white;
	position: absolute;
	top: -10px;
}

.contract-node-icon {
	cursor: pointer;
	display: inline;
	font-weight: 0px;
	font-size: 16px;
	color: #0055af;
	height: 18px;
	width: 24px;
	background-color: white;
	position: absolute;
	top: -10px;
}

.nodes-column-alignment {
	display: flex;
	flex-direction: column;
}

.recursive-nodes {
	margin-left: 200px;
	margin-top: 20px;
}

.loader-alignment {
	display: flex;
	flex-direction: row;
}

.popup-image-container {
	margin: 2px;
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: left;
}

.cancel-button-wrapper {
	background: #ffffff;
	position: absolute;
	padding: 0;
	margin: 0;
	margin-left: 1080px;
	margin-top: -10px;
	box-shadow: 0px -2px -2px -1px rgba(0, 0, 0, 0.2);
}

.popup-image {
	padding: 20px;
}

.popper-style {
	padding-left: 200px;
	width: 1300px;
}

.relative-pos {
	position: relative;
}

.legend-text {
	cursor: pointer;
	color: #0055af;
}

.tree-filter-label-container {
	display: flex;
}

.tree-filter-label {
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	min-width: 227px;
	white-space: nowrap;
	margin-left: 255px;
}
